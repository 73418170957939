.community-content {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.community-content * {
    font-size: 12px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    color: #21232E !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline !important;
}

.community-content br,
.community-content img,
.community-content iframe {
    display: none !important;
}

.crawled-content {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.crawled-content * {
    font-size: 12px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    color: #21232E !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline !important;
}

.crawled-content br,
.crawled-content img,
.crawled-content iframe {
    display: none !important;
}

.post-content-review {
    word-break: break-all !important;
}

.article-content-review {
    font-size: 16px !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    color: #21232E !important;
}

.article-content-review p {
    margin: 0;
    padding: 0;
}

.post-content-review img, .article-content-review img {
    width: 100% !important;
    height: auto;
}

.post-content-review br, .article-content-review br {
    display: none !important;
}

.community-author-content {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 1;
    line-clamp: 1;
}
.reward .ant-descriptions-row {
    border-bottom: none !important;
}
.vote-item {
    margin-bottom: 16px;
    position: relative;
    height: 44px;
    background: #F6F7FC;
}
.vote-item .vote-bar {
    background: #fff1f0;
    height: 44px;
}
.vote-item .vote-cnt {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    height: 44px;
    left: 10px;
    right: 10px;
    font-size: 13px;
}
.vote-item .vote-cnt .t1 {
    font-weight: bold;
    color: #333;
}
.vote-item .vote-cnt .t2 {
    font-weight: 400;
    color: #999;
}

.post-topic-item {
    background: #F6F8FC;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: #21232E;
}

.comment-wrapper {
    background: white;
}

.comment-wrapper .title {
    font-size: 14px;
    line-height: 1.428;
    font-weight: 400;
    color: #868FA0;
    padding: 14px 0;
}

.comment-item {
    padding-top: 16px;
}

.comment-item .user-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    color: #868FA0;
    margin-left: 0;
    display: flex;
    align-items: center;
}

.comment-item .content {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    color: #21232E;
    margin-top: 2px;
}

.comment-wrapper .comment-more {
    padding: 12px 0;
    text-align: center;
    font-size: 12px;
    color: #2D69FF;
    line-height: 1.5;
    font-weight: 400;
    user-select: none;
}

.create-time {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    color: #868FA0;
    margin-top: 12px;
}

.comment-tip {
    background: #0266FF;
    padding: 2px 4px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    vertical-align: middle;
    margin-right: 4px;
}